import type {HandleClientError} from '@sveltejs/kit';

export const handleError: HandleClientError = ({error, event}) => {
	const message = error instanceof Error ? error.message : String(error);
	console.error(`[SvelteKit Error]: ${message}`);

	return {
		message,
	};
};
