import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,9,10,2,3];

export const dictionary = {
		"/": [~11],
		"/.well-known/change-password": [12],
		"/auth/duplicate-email": [22],
		"/cancellation": [23,[8]],
		"/invite": [24],
		"/invite/[token]": [25],
		"/sign-up": [26,[9]],
		"/sign-up/[step]": [27,[9]],
		"/upgrade-success": [28],
		"/workspace/[workspaceId]": [~29,[10]],
		"/workspace/[workspaceId]/billing": [~30,[10]],
		"/workspace/[workspaceId]/upgrade": [31,[10]],
		"/workspace/[workspaceId]/users": [32,[10]],
		"/[main]/[idOrToken]": [~13,[2]],
		"/[main]/[idOrToken]/change-plan": [14,[2,3]],
		"/[main]/[idOrToken]/embed": [15,[2]],
		"/[main]/[idOrToken]/export": [~16,[2]],
		"/[main]/[idOrToken]/forecast": [17,[2,4]],
		"/[main]/[idOrToken]/functional-chart": [18,[2,5]],
		"/[main]/[idOrToken]/integration/[provider]": [19,[2]],
		"/[main]/[idOrToken]/orgchart": [20,[2,6]],
		"/[main]/[idOrToken]/positions": [21,[2,7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';